import { Pipe, PipeTransform } from '@angular/core';
import { ICigar, ICigarRelatedLine } from '@models';

@Pipe({
  name: 'relatedLineToLine',
  standalone: true,
})
export class RelatedLineToLinePipe implements PipeTransform {
  transform(relatedLine: ICigarRelatedLine, ...args: any[]): ICigar {
    return {
      ...relatedLine,
      ProductId: null,
      LineId: relatedLine.Id,
    };
  }
}
