import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgoPipe } from '@shared/pipes/ago.pipe';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import {
  IonItem,
  IonAvatar,
  IonLabel,
  IonBadge,
} from '@ionic/angular/standalone';

@Component({
  selector: 'humidor-history-item',
  standalone: true,
  imports: [
    CommonModule,
    CreateSourceStandalonePipe,
    AgoPipe,
    IonItem,
    IonAvatar,
    IonLabel,
    IonBadge,
  ],
  template: `
    <ion-item *ngIf="item.AuditType !== 'Deleted'">
      <ion-avatar slot="start">
        <img
          *ngIf="item.ProductImageUrl"
          [src]="item.ProductImageUrl | createSource"
          src-fallback="assets/images/humidor-details-cigar.svg"
        />
        <img
          *ngIf="!item.ProductImageUrl"
          class="no-image"
          src="assets/images/humidor-details-cigar.svg"
        />
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <p>
          <b>{{ item.Date | ago | async }}</b> -
          {{ item.Date | date : 'short' }}
        </p>
        <h2>{{ item.ProductName }}</h2>
      </ion-label>
      <ion-badge
        class="text-lg"
        slot="end"
        [color]="item.Quantity > 0 ? 'success' : 'danger'"
      >
        {{ item.Quantity > 0 ? '+' + item.Quantity : item.Quantity }}
      </ion-badge>
    </ion-item>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class HumidorHistoryItemComponent implements OnInit {
  @Input() item;
  @Input() humidorId;

  constructor(private router: Router) {}

  ngOnInit() {}

  navigateToCigarDetails() {
    this.router.navigate(['cigar', `P-${this.item.ProductId}`]);
  }
}
