import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  IProductSearchFilters,
  IProductSearch,
  IProductSearchParams,
} from '@models';

export const PER_PAGE = 20;

@Injectable({
  providedIn: 'root',
})
export class CigarsSearchService {
  constructor(private http: HttpClient) {}

  getList(
    filters: IProductSearchFilters = { Search: '' },
    skip = 0,
    take = PER_PAGE
  ) {
    let params = new HttpParams();
    if (take) {
      params = params.set('Take', take.toString());
    }
    if (skip) {
      params = params.set('Skip', skip.toString());
    }
    return this.http.post<IProductSearch[]>(`cigars/powersearch`, filters, {
      params: params,
      observe: 'response',
    });
  }

  searchParams: IProductSearchParams;
  getSearchParams() {
    if (this.searchParams) {
      return of(this.searchParams);
    }

    return this.http
      .post<IProductSearchParams>(`cigars/powersearch/params`, {})
      .pipe(
        tap((searchParams) => {
          this.searchParams = searchParams;
        })
      );
  }
}
