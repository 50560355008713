import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { IProductSearchFilters } from '@models';
import { ModalService } from '@services/modal.service';
import { PowerSearchParamsModel } from '@shared/models/power-search-params.model';
import { RangeDualKnobsModel } from '@shared/models/range-dual-knobs.model';
import _findIndex from 'lodash-es/findIndex';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonRange, IonToggle } from "@ionic/angular/standalone";

export interface IAdvancedSearchModalData {
    searchParams: PowerSearchParamsModel;
    currentValues: IProductSearchFilters | null;
}
interface IFormValue {
    Shapes: number[];
    Origins: number[];
    Sections: number[];
    Wrappers: number[];
    wrapperColorEnabled: boolean;
    wrapperColor: number;
    strengthEnabled: boolean;
    strength: number;
    lengthRange: RangeDualKnobsModel;
    ringGaugeRange: RangeDualKnobsModel;
}

@Component({
    selector: 'advanced-search.modal',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonRange, IonToggle],
    styleUrls: ['advanced-search.modal.scss'],
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button fill="clear" color="dark" (click)="clear()">
            Clear
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button
            fill="outline"
            shape="round"
            color="primary"
            (click)="search()"
            [strong]="true"
          >
            Submit
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form [formGroup]="formGroup">
        <ion-list>
          <ion-item lines="full">
            <ion-label>Shape</ion-label>
            <ion-select formControlName="Shapes" multiple="true">
              <ion-select-option
                *ngFor="let cigar of searchParams.Shapes"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <ion-item lines="full">
            <ion-label>Section</ion-label>
            <ion-select formControlName="Sections" multiple="true">
              <ion-select-option
                *ngFor="let cigar of searchParams.Sections"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item lines="full">
            <ion-label>Cigar Origin</ion-label>
            <ion-select formControlName="Origins" multiple="true">
              <ion-select-option
                *ngFor="let cigar of searchParams.Origins"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <ion-item lines="full">
            <ion-label>Wrapper</ion-label>
            <ion-select
              [interfaceOptions]="customAlertOptions"
              interface="alert"
              formControlName="Wrappers"
              multiple="true"
            >
              <ion-select-option
                *ngFor="let cigar of searchParams.Wrappers"
                [value]="cigar.Id"
                >{{ cigar.Name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item lines="full">
            <ion-label>Length</ion-label>
          </ion-item>
          <ion-item lines="full">
            <ion-range
              #ionLengthRange
              class="dual-knobs"
              dualKnobs
              snaps="true"
              pin="true"
              ticks="false"
              formControlName="lengthRange"
            >
              <ion-label slot="start">{{ searchParams.Lengths[0] }}</ion-label>
              <ion-label slot="end">{{
                searchParams.RingGauges[searchParams.Lengths.length - 1]
              }}</ion-label>
            </ion-range>
          </ion-item>
          <ion-item lines="full">
            <ion-label>Ring Gauge</ion-label>
          </ion-item>
          <ion-item lines="full">
            <ion-range
              #ionGaugeRange
              class="dual-knobs"
              dualKnobs
              snaps="true"
              pin="true"
              ticks="false"
              formControlName="ringGaugeRange"
            >
              <ion-label slot="start">{{
                searchParams.RingGauges[0]
              }}</ion-label>
              <ion-label slot="end">{{
                searchParams.RingGauges[searchParams.RingGauges.length - 1]
              }}</ion-label>
            </ion-range>
          </ion-item>
          <ion-item lines="full">
            <ion-label>Strength</ion-label>
            <ion-toggle
              mode="ios"
              formControlName="strengthEnabled"
              (ionChange)="toggleStrenghtColor($event)"
            ></ion-toggle>
          </ion-item>
          <ion-item lines="none" class="overflow-visible">
            <ion-range
              #ionStrengthRange
              class="strength ml-2"
              step="1"
              [ticks]="false"
              snaps="true"
              formControlName="strength"
              [ngClass]="{ opacity: !formGroup.value.strengthEnabled }"
            >
            </ion-range>
          </ion-item>
          <ion-item lines="full">
            <div class="slider-values strength">
              <span *ngFor="let item of searchParams.Strengths">{{
                item.Name
              }}</span>
            </div>
          </ion-item>
          <ion-item lines="full">
            <ion-label>Wrapper Color</ion-label>
            <ion-toggle
              mode="ios"
              formControlName="wrapperColorEnabled"
              (ionChange)="toggleWrapperColor($event)"
            ></ion-toggle>
          </ion-item>
          <ion-item lines="none" class="overflow-visible">
            <ion-range
              #ionColorRange
              class="color-wrapper ml-2"
              step="1"
              [ticks]="false"
              snaps="true"
              formControlName="wrapperColor"
              [ngClass]="{ opacity: !formGroup.value.wrapperColorEnabled }"
            >
            </ion-range>
          </ion-item>
          <ion-item lines="full">
            <div class="slider-values vertical">
              <span
                *ngFor="
                  let item of searchParams.WrapperColors.slice().reverse()
                "
                >{{ item.Name }}</span
              >
            </div>
          </ion-item>
        </ion-list>
      </form>
    </ion-content>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchModal implements OnInit {
    @ViewChild('ionLengthRange', { static: true }) $lengthRange;
    @ViewChild('ionGaugeRange', { static: true }) $gaugeRange;
    @ViewChild('ionStrengthRange', { static: true }) $strengthRange;
    @ViewChild('ionColorRange', { static: true }) $colorRange;
    customAlertOptions = {
        cssClass: 'ion-text-wrap',
    };

    formGroup: UntypedFormGroup;
    @Input() searchParams: PowerSearchParamsModel;
    @Input() currentValues: IProductSearchFilters | null;
    constructor(private modalService: ModalService) {
        this.formGroup = new UntypedFormGroup({
            Shapes: new UntypedFormControl(''),
            Sections: new UntypedFormControl(''),
            Origins: new UntypedFormControl(''),
            Wrappers: new UntypedFormControl(''),
            lengthRange: new UntypedFormControl({
                lower: 0,
                upper: 11,
            }),
            ringGaugeRange: new UntypedFormControl({
                lower: 0,
                upper: 13,
            }),
            strengthEnabled: new UntypedFormControl(false),
            strength: new UntypedFormControl(0),
            wrapperColorEnabled: new UntypedFormControl(false),
            wrapperColor: new UntypedFormControl(0),
        });
    }

    ngOnInit() {
        // All of this is a hack because ionic 5.11
        // breaks if formControlName and min/max are set
        // on the same element

        this.$lengthRange.el.min = this.searchParams.Lengths[0];
        this.$lengthRange.el.max =
            this.searchParams.Lengths[this.searchParams.Lengths.length - 1];
        this.$gaugeRange.el.min = this.searchParams.RingGauges[0];
        this.$gaugeRange.el.max =
            this.searchParams.RingGauges[this.searchParams.RingGauges.length - 1];
        this.$strengthRange.el.max = this.searchParams.Strengths.length - 1;
        this.$colorRange.el.max = this.searchParams.WrapperColors.length - 1;

        const {
            LengthFrom = null,
            LengthTo = null,
            RingGaugeFrom = null,
            RingGaugeTo = null,
            Shapes = [],
            Origins = [],
            Sections = [],
            Wrappers = [],
            WrapperColors = [],
            Strengths = [],
        } = this.currentValues || {};

        this.formGroup.setValue({
            lengthRange: {
                lower: LengthFrom || this.searchParams.Lengths[0],
                upper:
                    LengthTo ||
                    this.searchParams.Lengths[this.searchParams.Lengths.length - 1],
            },
            ringGaugeRange: {
                lower: RingGaugeFrom || this.searchParams.RingGauges[0],
                upper:
                    RingGaugeTo ||
                    this.searchParams.RingGauges[this.searchParams.RingGauges.length - 1],
            },
            wrapperColor: WrapperColors.length
                ? _findIndex(this.searchParams.WrapperColors.slice().reverse(), {
                    Id: WrapperColors[0],
                })
                : 0,
            strength: Strengths.length
                ? _findIndex(this.searchParams.Strengths, {
                    Id: Strengths[0],
                })
                : 0,
            Shapes,
            Origins,
            Sections,
            Wrappers,
            strengthEnabled: Strengths.length > 0,
            wrapperColorEnabled: WrapperColors.length > 0,
        });
    }

    toggleWrapperColor(e: Event) {
        const checked = (<CustomEvent<{ checked: boolean }>>e).detail.checked;
        if (!checked) {
            this.formGroup.setValue({
                ...this.formGroup.value,
                wrapperColor: 0,
            });
        }
    }

    toggleStrenghtColor(e: Event) {
        const checked = (<CustomEvent<{ checked: boolean }>>e).detail.checked;
        if (!checked) {
            this.formGroup.setValue({
                ...this.formGroup.value,
                strength: 0,
            });
        }
    }

    search() {
        const {
            Shapes,
            Origins,
            Sections,
            Wrappers,
            wrapperColorEnabled,
            wrapperColor,
            strengthEnabled,
            strength,
            lengthRange,
            ringGaugeRange,
        } = this.formGroup.value as IFormValue;

        this.modalService.dismiss(
            {
                Search: '',
                Shapes,
                Origins,
                Sections,
                Wrappers,
                WrapperColors: wrapperColorEnabled
                    ? [this.searchParams.WrapperColors.slice().reverse()[wrapperColor].Id]
                    : [],
                Strengths: strengthEnabled
                    ? [this.searchParams.Strengths[strength].Id]
                    : [],
                LengthFrom: lengthRange.lower,
                LengthTo: lengthRange.upper,
                RingGaugeFrom: ringGaugeRange.lower,
                RingGaugeTo: ringGaugeRange.upper,
            } as Partial<IProductSearchFilters>,
            'submit'
        );
    }

    clear() {
        this.modalService.dismiss(null, 'clear');
    }

    dismiss() {
        this.modalService.dismiss(null, 'cancel');
    }
}
