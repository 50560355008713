import {
  ICigarLog,
  ICigarLogFilters,
  ICigarLogListSortNameEnum,
  ICigarLogStored,
  ILogsListStore,
  IMyCigarsState,
} from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as userActions from '@store/actions/user';
import { getCigarCreatedId } from '@utils/cigar';
import * as myCigarsActions from '@store/actions/my-cigars';

export const adapter: EntityAdapter<ICigarLogStored> =
  createEntityAdapter<ICigarLogStored>({
    selectId: (entity: ICigarLogStored) => entity.Id,
  });

export const initialState: IMyCigarsState = adapter.getInitialState({
  loadingItems: {},
  ids: null,
});

export const MyCigarsReducer = createReducer(
  initialState,
  on(
    myCigarsActions.MyCigarsQuantityUpdateRequest,
    myCigarsActions.MyCigarsPriceUpdateRequest,
    (state, { cigarLog }) => ({
      ...state,
      loadingItems: {
        ...state.loadingItems,
        [cigarLog.Id]: true,
      },
    })
  ),
  on(
    myCigarsActions.MyCigarsQuantityUpdateSuccess,
    myCigarsActions.MyCigarsQuantityUpdateError,
    myCigarsActions.MyCigarsPriceUpdateSuccess,
    myCigarsActions.MyCigarsPriceUpdateError,
    (state, { cigarLog }) => ({
      ...state,
      loadingItems: {
        ...state.loadingItems,
        [cigarLog.Id]: false,
      },
    })
  ),

  on(
    myCigarsActions.MyCigarsFiltersUpdateAction,
    (state, { listType, filters }) => ({
      ...state,
      [listType]: {
        ...getInitialListingState(listType),
        filters,
      },
    })
  ),
  on(myCigarsActions.MyCigarsFiltersClearSuccess, (state, { listType }) => ({
    ...state,
    [listType]: getInitialListingState(listType),
  })),
  on(
    myCigarsActions.MyCigarsRequestAction,
    myCigarsActions.MyCigarsCreateRequestAction,
    (state, { listType }) => ({
      ...state,
      [listType]: {
        ...(state[listType] || getInitialListingState(listType)),
        isLoading: true,
        error: '',
      },
    })
  ),
  on(
    myCigarsActions.MyCigarsSuccessAction,
    (state, { listType, cigarLogs, skip, total }) => {
      const ids = [
        ...(skip === 0 ? [] : state[listType].ids),
        ...cigarLogs.map((e) => e.Id),
      ];
      const { entities } = adapter.upsertMany(
        cigarLogs.map(fromCigarLogToCigarLogStored),
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        [listType]: {
          ...state[listType],
          total,
          ids,
          isLoading: false,
          showMore: ids.length < total,
          error: '',
        },
      };
    }
  ),
  on(
    myCigarsActions.MyCigarsGetOneSuccessAction,
    (state, { listType, cigarLog }) => {
      return {
        ...state,
        entities: adapter.upsertOne(fromCigarLogToCigarLogStored(cigarLog), {
          entities: state.entities,
          ids: [],
        }).entities,
      };
    }
  ),
  on(
    myCigarsActions.MyCigarsCreateSuccessAction,
    (state, { listType, cigarLog }) => {
      if (!state[listType]) return state; // not loaded yet but can be created from sockets
      // if we already
      // const ids = state[listType].ids?.length
      //   ? [...new Set([...state[listType].ids, cigarLog.Id])]
      //   : [];
      return {
        ...state,
        entities: adapter.upsertOne(fromCigarLogToCigarLogStored(cigarLog), {
          entities: state.entities,
          ids: [],
        }).entities,
        [listType]: {
          ...state[listType],
          isLoading: false,
          // ids,
        },
      };
    }
  ),
  on(
    myCigarsActions.MyCigarsUpdateSuccessAction,
    myCigarsActions.MyCigarsQuantityUpdateSuccess,
    myCigarsActions.MyCigarsPriceUpdateSuccess,
    (state, { cigarLog }) => {
      return {
        ...state,
        entities: adapter.upsertOne(fromCigarLogToCigarLogStored(cigarLog), {
          entities: state.entities,
          ids: [],
        }).entities,
      };
    }
  ),
  on(
    myCigarsActions.MyCigarsDeleteSuccessAction,
    (state, { listType, cigarLog }) => {
      if (!state[listType]) return state; // not loaded yet but can be deleted from sockets

      const isIncluded = state[listType].ids.includes(cigarLog.Id);
      if (!isIncluded) return state;

      return {
        ...state,
        entities: adapter.removeOne(cigarLog.Id, {
          entities: state.entities,
          ids: [],
        }).entities,
        [listType]: {
          ...state[listType],
          total: state[listType].total - 1,
          ids: state[listType].ids.filter((id) => id !== cigarLog.Id),
        },
      };
    }
  ),
  // from the socket we only have the log id, we don't know in which list it could be
  // so we need to iterate over all lists and remove the log id from the list
  on(myCigarsActions.MyCigarsDeleteFromSocket, (state, { cigarLogId }) => {
    const newState = Object.keys(state).reduce((acc, key) => {
      if (key === 'ids' || key === 'entities') return acc;
      const list = state[key];
      if (!list?.ids) return acc;
      acc[key] = {
        ...list,
        ids: list.ids.filter((id) => id !== cigarLogId),
      };
      return acc;
    }, {});
    return {
      ...state,
      entities: adapter.removeOne(cigarLogId, {
        entities: state.entities,
        ids: [],
      }).entities,
      ...newState,
    };
  }),
  on(userActions.SummarySuccess, (state, { summary }) => ({
    ...state,
    journal: {
      ...(state['journal'] || getInitialListingState('journal')),
      total: summary.CigarsInJournal,
    },
    wishlist: {
      ...(state['wishlist'] || getInitialListingState('wishlist')),
      total: summary.CigarsInWishlist,
    },
    favorites: {
      ...(state['favorites'] || getInitialListingState('favorites')),
      total: summary.CigarsInFavorites,
    },
  })),
  on(myCigarsActions.MyCigarsErrorAction, (state, { listType, error }) => ({
    ...state,
    [listType]: {
      ...state[listType],
      isLoading: false,
      error,
    },
  })),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

function fromCigarLogToCigarLogStored(cigarLog: ICigarLog): ICigarLogStored {
  return {
    ...cigarLog,
    CigarDetails: getCigarCreatedId(cigarLog.CigarDetails),
  };
}

export default MyCigarsReducer;

function getInitialListingState(key: string): ILogsListStore {
  return {
    total: 0,
    ids: null,
    isLoading: false,
    showMore: true,
    filters: (() => {
      const filters = localStorage.getItem(`logs:filters:${key}`);

      try {
        if (filters) {
          return JSON.parse(filters);
        }
      } catch (error) {}

      return getInitialFilters();
    })(),
    error: '',
  };
}

export function getInitialFilters(): ICigarLogFilters {
  return {
    sort: ICigarLogListSortNameEnum.DATE,
    order: 'desc',
    search: '',
  };
}
