import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'confidence-bar',
  template: `
    <span
      class="inline-flex items-center gap-x-1.5 rounded-full px-1.5 py-0.5 text-xs font-medium"
      [ngClass]="background()"
    >
      Confidence: {{ value | percent }}
    </span>
  `,
})
export class ConfidenceBarComponent implements OnInit {
  @Input() value: number;

  constructor() {}

  ngOnInit() {}

  background() {
    if (this.value >= 90) {
      return 'bg-confidence-90 text-gray-900';
    } else if (this.value >= 80) {
      return 'bg-confidence-80 text-gray-900';
    } else if (this.value >= 70) {
      return 'bg-confidence-70 text-gray-900';
    } else if (this.value >= 60) {
      return 'bg-confidence-60 text-gray-900';
    } else if (this.value >= 50) {
      return 'bg-confidence-50 text-gray-900';
    } else if (this.value >= 40) {
      return 'bg-confidence-40 text-gray-900';
    } else if (this.value >= 30) {
      return 'bg-confidence-30 text-white';
    } else if (this.value >= 20) {
      return 'bg-confidence-20 text-white';
    } else if (this.value >= 10) {
      return 'bg-confidence-10  text-white';
    } else {
      return 'bg-confidence-0 text-white';
    }
  }
}
