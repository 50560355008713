import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, Platform, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonItem, IonLabel } from '@ionic/angular/standalone';
import { DataSummaryService } from '@services/datasummary.service';
import { ModalService } from '@services/modal.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { RecognitionService } from '@services/recognition.service';
import { ScanLogModel } from '@shared/models/scan-log.model';
import { map } from 'rxjs';
import { addIcons } from "ionicons";
import { close, people, camera, search } from "ionicons/icons";

export type ScannerNoResultsModalResult = {
    data: null;
    role: 'backdrop' | 'cancel' | 'camera' | 'search';
};

@Component({
    standalone: true,
    imports: [CommonModule, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonItem, IonLabel],
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Scan result</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-item>
        <img
          slot="start"
          alt="Cigar image"
          class="object-scale-down max-h-[25vh] max-w-[25%]"
          *ngIf="scanLog?.cigarImageUrl"
          [src]="scanLog.cigarImageUrl"
        />
        <ion-label class="ion-text-wrap">
          <h3>Oops, We couldn't recognize your cigar!</h3>
          <p>
            We suggest you to also try to search your cigar by name using the
            button below. Image recognition may fail for different reasons but
            we may have your cigar in our database.
            <span *ngIf="count$ | async as count">
              We currently carry {{ count }} cigars in our database!
            </span>
          </p>
        </ion-label>
      </ion-item>
      <ion-button
        class="mt-2"
        expand="block"
        color="primary"
        (click)="requestManualRecognition()"
      >
        <ion-icon slot="start" name="people"></ion-icon>
        Ask Our Team To Recognize It
      </ion-button>
      <ion-button
        class="mt-2"
        fill="outline"
        expand="block"
        color="primary"
        (click)="takePicture()"
        ><ion-icon slot="start" name="camera"></ion-icon>
        Retake Picture
      </ion-button>
      <ion-button
        class="mt-2"
        fill="outline"
        expand="block"
        color="primary"
        (click)="SearchByName()"
      >
        <ion-icon slot="start" name="search"></ion-icon>
        Search by Name
      </ion-button>
    </ion-content>
  `,
})
export class ScannerNoResultsModal implements OnInit {
    @Input() scanLog: ScanLogModel;

    count$ = this.dataSummaryService.dataSummary().pipe(map((e) => e.Products));

    constructor(
        private modalService: ModalService,
        private platform: Platform,
        private recognitionService: RecognitionService,
        private pushNotificationsService: PushNotificationsService,
        private alertCtrl: AlertController,
        private dataSummaryService: DataSummaryService
    ) {
        addIcons({ close, people, camera, search });
    }

    ngOnInit() { }

    getPlatform() {
        if (this.platform.is('ios')) {
            return 'iOS';
        } else if (this.platform.is('android')) {
            return 'Android';
        } else {
            return 'Web';
        }
    }

    dismiss() {
        this.modalService.dismiss(null, 'cancel');
    }

    SearchByName() {
        this.modalService.dismiss(null, 'search');
    }

    takePicture() {
        this.modalService.dismiss(null, 'camera');
    }

    ionViewWillEnter() {
        if (!this.scanLog) {
            this.dismiss();
        }
    }

    requestManualRecognition() {
        this.recognitionService
            .requestManualRecognition({
                LogId: this.scanLog.logId,
                ImageUrl: this.scanLog.originalImageUrl,
                Platform: this.getPlatform(),
            })
            .subscribe((res) => {
                this.alertCtrl
                    .create({
                        header: 'Manual Recognition Received',
                        subHeader:
                            "Our team of experts will now take a look at your picture. You'll get notified once they identify your cigar. Make sure you allow Cigar Scanner to send you notifications.",
                        backdropDismiss: false,
                        buttons: [
                            {
                                text: 'OK',
                                role: 'confirm',
                                handler: () => {
                                    this.pushNotificationsService.askForPermission();
                                    this.dismiss();
                                },
                            },
                        ],
                    })
                    .then((alert) => {
                        alert.present();
                    });
            });
    }
}
