import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ConfidenceBarComponent } from '@components/confidence-bar.component';
import { ICigar, ICigarLogListType, IScan, IState } from '@models';
import { select, Store } from '@ngrx/store';
import { CigarsService } from '@services/cigars.service';
import { ModalService } from '@services/modal.service';
import { CigarBrandLogoComponent } from '@shared/components/cigar-brand-logo/cigar-brand-logo.component';
import { CigarInfoComponent } from '@shared/components/cigar-info/cigar-info.component';
import { RemoveLineNamePipe } from '@shared/pipes/remove-line-name.pipe';
import * as userSelectors from '@store/selectors/user';
import { isListHumidor } from '@utils/cigarlogs';
import { first, firstValueFrom } from 'rxjs';

import { RelatedLineToLinePipe } from './related-line.pipe';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
} from '@ionic/angular/standalone';

export type ScannerAIResultsModalResult =
  | {
      data: {
        scanLog: IScan;
        cigar: ICigar;
        addTo: string;
      };
      role: 'add-cigar';
    }
  | {
      data: {
        scanLog: IScan;
        cigar: ICigar;
      };
      role: 'go-to-cigar';
    }
  | {
      data: {
        scanLog: IScan;
        cigar: ICigar;
        addTo: string;
      };
      role: 'add-and-go-to-cigar';
    }
  | {
      data: null;
      role: 'cancel';
    };

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CigarInfoComponent,
    CigarBrandLogoComponent,
    RelatedLineToLinePipe,
    RemoveLineNamePipe,
    ConfidenceBarComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonAccordionGroup,
    IonAccordion,
    IonItem,
    IonLabel,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Scan result</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list lines="full">
        <ng-container *ngFor="let result of scanLog.results">
          <cigar-info
            *ngIf="result.RecognitionResult.ProductId"
            [cigar]="result.Cigar"
            [rating]="result.Cigar.RatingSummary"
            [name]="result.Cigar.Name"
            [prices]="result.Cigar.Prices"
            [minBoxQty]="result.Cigar.MinBoxQty"
            [maxBoxQty]="result.Cigar.MaxBoxQty"
            (cigarClicked)="addProduct(result.Cigar)"
          >
            <confidence-bar
              belowTitle
              class="mt-1"
              [value]="result.RecognitionResult.Score"
            ></confidence-bar>
          </cigar-info>
          <ng-container *ngIf="!result.RecognitionResult.ProductId">
            <cigar-info
              class="transparent"
              [cigar]="result.Cigar"
              [rating]="result.Cigar.RatingSummary"
              [name]="result.Cigar.Name"
              [prices]="result.Cigar.Prices"
              [minBoxQty]="result.Cigar.MinBoxQty"
              [maxBoxQty]="result.Cigar.MaxBoxQty"
              (cigarClicked)="addLine(result.Cigar.LineId)"
            >
              <confidence-bar
                belowTitle
                class="mt-1"
                [value]="result.RecognitionResult.Score"
              ></confidence-bar>
            </cigar-info>
            <ng-container *ngIf="result.Cigar.RelatedLines?.length">
              <ion-accordion-group>
                <ion-accordion value="first">
                  <ion-item slot="header" color="light" class="ion-text-nowrap">
                    <ion-label>{{ result.Cigar.Name }} similar bands</ion-label>
                  </ion-item>
                  <div slot="content">
                    <cigar-info
                      *ngFor="let relatedLine of result.Cigar.RelatedLines"
                      [cigar]="relatedLine | relatedLineToLine"
                      [rating]="relatedLine.Rating"
                      [name]="relatedLine.Name"
                      [prices]="relatedLine.Prices"
                      [minBoxQty]="relatedLine.MinBoxQty"
                      [maxBoxQty]="relatedLine.MaxBoxQty"
                      (cigarClicked)="addLine(relatedLine.Id)"
                    >
                    </cigar-info>
                  </div>
                </ion-accordion>
              </ion-accordion-group>
            </ng-container>
          </ng-container>
        </ng-container>
      </ion-list>
    </ion-content>
  `,
  styles: [
    `
      cigar-info {
        border-color: var(
          --ion-item-border-color,
          var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
        );
        border-width: 0 0 1px 0;
        border-style: solid;
        padding: 5px 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerAIResultsModal implements OnInit {
  @Input() scanLog: IScan;
  @Input() addTo?: ICigarLogListType;

  isHumidor = false;
  settings$ = this.store.pipe(select(userSelectors.selectSettings));

  constructor(
    private modalService: ModalService,
    private store: Store<IState>,
    private cigarsService: CigarsService
  ) {
    addIcons({ close });
  }

  ngOnInit() {
    this.isHumidor = isListHumidor(this.addTo);
    if (!this.scanLog) {
      this.dismiss();
    }
  }

  dismiss() {
    this.modalService.dismiss(null, 'cancel');
  }

  async addProduct(cigar: ICigar) {
    this.exitLogic(this.scanLog, cigar);
  }

  async addLine(LineId: number) {
    const result = await this.cigarsService.displayLineSizeChoice(
      LineId,
      !this.isHumidor
    );
    if (!result) return;
    this.exitLogic(this.scanLog, result as ICigar);
  }

  private async exitLogic(scanLog: IScan, cigar: ICigar) {
    const settings = await firstValueFrom(this.settings$.pipe(first()));
    if (this.addTo) {
      this.addCigar(scanLog, cigar);
    } else if (settings?.AutoAddScanned) {
      this.addAndGoToCigar(this.scanLog, cigar);
    } else {
      this.goTo(cigar);
    }
  }

  private async addCigar(scanLog: IScan, cigar: ICigar) {
    this.modalService.dismiss(
      { scanLog, cigar, addTo: this.addTo },
      'add-cigar'
    );
  }
  private async addAndGoToCigar(scanLog: IScan, cigar: ICigar) {
    this.modalService.dismiss(
      { scanLog, cigar, addTo: this.addTo },
      'add-and-go-to-cigar'
    );
  }

  private async goTo(cigar: ICigar) {
    this.modalService.dismiss(
      {
        cigar,
      },
      'go-to-cigar'
    );
  }
}
