import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { environment } from '@environments/environment';
import {
  InfiniteScrollCustomEvent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/angular/standalone';
import { IHumidorTransaction } from '@models';
import { HumidorsService } from '@services/humidor.service';
import { ModalService } from '@services/modal.service';
import { HumidorHistoryItemComponent } from '@shared/components/humidor-history-item/humidor-history-item';
import { AppRoutes } from '@utils/routes';
import { finalize, tap } from 'rxjs';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    HumidorHistoryItemComponent,
    ScrollingModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonProgressBar,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>History</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-progress-bar type="indeterminate" *ngIf="isLoading">
      </ion-progress-bar>
    </ion-header>
    <ion-content>
      <ion-item color="light" *ngIf="!isLoading && !list.length">
        <ion-label>No history</ion-label>
      </ion-item>
      <ion-list lines="full">
        <humidor-history-item
          class="w-full"
          *ngFor="let item of list"
          [item]="item"
          [humidorId]="humidorId"
        ></humidor-history-item>
      </ion-list>
      <ion-infinite-scroll
        [disabled]="reachedEnd"
        threshold="100px"
        (ionInfinite)="loadMore($event)"
      >
        <ion-infinite-scroll-content> </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class HumidorHistoryModal implements OnInit {
  public routes = AppRoutes;
  list: IHumidorTransaction[] = [];
  @Input() productId: number;
  @Input() humidorId: string;

  isLoading = true;
  reachedEnd = false;

  constructor(
    private humidorsService: HumidorsService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.loadMore();
  }

  loadMore(event?: InfiniteScrollCustomEvent) {
    this.isLoading = true;
    this.cdr.detectChanges();
    return this.humidorsService
      .getTransactionsHistory(this.humidorId, {
        skip: this.list.length,
        productId: this.productId,
      })
      .pipe(
        tap((list) => {
          this.list = [...this.list, ...list];
          if (list.length < environment.pageLimit) {
            this.reachedEnd = true;
          }
          this.cdr.detectChanges();
        }),
        finalize(() => {
          if (event) {
            event.target.complete();
          }
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
