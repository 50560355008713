import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NAMESPACE, adapter } from '../reducers/cigars-search';
import { ICigarsSearchState, IProductSearchFilters } from '@models';

export const selectCigarsSearchState =
  createFeatureSelector<ICigarsSearchState>(NAMESPACE);

const { selectAll, selectTotal: selectTotalLocal } = adapter.getSelectors(
  selectCigarsSearchState
);

export const selectTotal = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.total
);
export const selectIsLoading = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.isLoading
);
export const selectError = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.error
);
export const selectFilters = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.filters
);
export const selectHasFilters = createSelector(
  selectFilters,
  (filters: IProductSearchFilters | null) => {
    return filters !== null;
  }
);
export const selectTerm = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.term
);
export const selectList = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.list
);
export const selectShowMore = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.showMore
);
export const selectIsInit = createSelector(
  selectCigarsSearchState,
  (state: ICigarsSearchState) => state.init
);
export const selectCigarsSearch = selectAll;
export const selectCount = selectTotalLocal;
