import { on, createReducer } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { ICigarsState, ICigarLite, ICigar } from '@models';
import { getCigarCreatedId } from '@utils/cigar';
import * as userActions from '@store/actions/user';

import * as myCigarsActions from '@store/actions/my-cigars';
import * as CigarsActions from '@store/actions/cigars';

export const adapter: EntityAdapter<ICigar | ICigarLite> = createEntityAdapter<
  ICigar | ICigarLite
>({
  selectId: (entity: ICigarLite) => getCigarCreatedId(entity),
});

export const initialState: ICigarsState = adapter.getInitialState({
  loadingItems: {},
});

export const CigarsReducer = createReducer(
  initialState,
  on(CigarsActions.CigarRequest, (state) => ({
    ...state,
    isLoading: true,
    error: '',
  })),
  on(
    CigarsActions.CigarUpdateFeaturesRequest,
    CigarsActions.CigarUpdateNoteRequest,
    CigarsActions.CigarUpdateReviewRequest,
    CigarsActions.CigarDeleteNoteRequest,
    CigarsActions.CigarDeleteReviewRequest,
    (state, { cigar }) => ({
      ...state,
      loadingItems: {
        ...state.loadingItems,
        [getCigarCreatedId(cigar)]: true,
      },
    })
  ),
  on(
    CigarsActions.CigarUpdateFeaturesError,
    CigarsActions.CigarUpdateFeaturesSuccess,
    CigarsActions.CigarUpdateNoteError,
    CigarsActions.CigarUpdateNoteSuccess,
    CigarsActions.CigarUpdateReviewSuccess,
    CigarsActions.CigarUpdateReviewError,
    CigarsActions.CigarDeleteNoteSuccess,
    CigarsActions.CigarDeleteNoteError,
    CigarsActions.CigarDeleteReviewSuccess,
    CigarsActions.CigarDeleteReviewError,
    (state, { cigar }) => ({
      ...state,
      loadingItems: {
        ...state.loadingItems,
        [getCigarCreatedId(cigar)]: false,
      },
    })
  ),
  on(myCigarsActions.MyCigarsSuccessAction, (state, { cigarLogs }) => ({
    ...state,
    entities: adapter.upsertMany(
      cigarLogs.map((log) => log.CigarDetails),
      state
    ).entities,
  })),
  on(
    myCigarsActions.MyCigarsUpdateSuccessAction,
    myCigarsActions.MyCigarsGetOneSuccessAction,
    myCigarsActions.MyCigarsCreateSuccessAction,
    (state, { cigarLog }) => ({
      ...state,
      entities: adapter.upsertOne(cigarLog.CigarDetails, state).entities,
    })
  ),
  on(
    CigarsActions.CigarUpdateNoteSuccess,
    CigarsActions.CigarDeleteNoteSuccess,
    (state, { cigar, note }) => {
      const isIncluded = state.entities[getCigarCreatedId(cigar)];
      if (!isIncluded) {
        // case where we receive a socket event on a cigar not loaded locally
        return state;
      }
      return {
        ...state,
        entities: adapter.upsertOne(
          {
            ...cigar,
            MyNote: note,
          },
          state
        ).entities,
      };
    }
  ),
  on(
    CigarsActions.CigarUpdateReviewSuccess,
    CigarsActions.CigarDeleteReviewSuccess,
    (state, { cigar, review }) => {
      const isIncluded = state.entities[getCigarCreatedId(cigar)];
      if (!isIncluded) {
        // case where we receive a socket event on a cigar not loaded locally
        return state;
      }
      return {
        ...state,
        entities: adapter.upsertOne(
          {
            ...cigar,
            MyRating: review,
          },
          state
        ).entities,
      };
    }
  ),
  on(
    CigarsActions.CigarUpdateFeaturesSuccess,
    (state, { cigar, features }) => ({
      ...state,
      entities: adapter.upsertOne(
        {
          ...cigar,
          MyCigarFeatures: features,
        },
        state
      ).entities,
    })
  ),
  on(
    CigarsActions.CigarSuccess,
    CigarsActions.CigarCreateCustomSuccess,
    CigarsActions.CigarUpdateCustomSuccess,
    (state, { cigar }) => ({
      ...state,
      entities: adapter.upsertOne(cigar, state).entities,
    })
  ),
  on(CigarsActions.CigarError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default CigarsReducer;
