import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { Platform } from '@ionic/angular/standalone';
import { INotification } from '@models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  device: DeviceInfo;
  constructor(private http: HttpClient, private platform: Platform) {
    Device.getInfo().then((device) => (this.device = device));
  }

  getList(
    skip: number,
    take: number = 50, // = environment.pageLimit, @todo use this when https://app.asana.com/0/191724940562644/1205568544220188/f is done
    unreadOnly = false
  ) {
    let params = new HttpParams();
    params = params.set('UnreadOnly', unreadOnly.toString());
    params = params.set('Skip', skip.toString());
    params = params.set('Take', take.toString());
    return this.http.get<INotification[]>('notifications', {
      params,
      observe: 'response',
    });
  }

  head() {
    return this.http.head<void>('notifications', {
      observe: 'response',
    });
  }

  read(id: number) {
    return this.http.post<INotification>(`notifications/${id}/dismiss`, {});
  }

  unread(id: number) {
    return this.http.post<INotification>(
      `notifications/${id}/dismiss?undo=true`,
      {}
    );
  }

  delete(id: number) {
    return this.http.delete<INotification>(`notifications/${id}`, {});
  }

  deleteAll() {
    return this.http.post<void>('notifications/deleteall', {});
  }

  readAll() {
    return this.http.post<void>('notifications/dismissall', {});
  }

  getState() {
    return this.http.get('notifications/state');
  }

  subscribe(token: string) {
    const data = {
      NotificationToken: token,
      Platform: 'Firebase',
      Misc: 'CSv3 mobile app v.1.0',
      DeviceManufacturer: null,
      DeviceModel: null,
    };

    if (this.platform.is('capacitor')) {
      data.DeviceManufacturer = this.device.manufacturer;
      data.DeviceModel = this.device.model;
    }

    return this.http.post('notifications/subscribe', data);
  }

  unsubscribe() {
    return this.http.post('notifications/unsubscribe', {});
  }
}
