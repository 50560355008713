import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

declare var MarvinImage: any;
declare var Segmenter: any;

@Injectable({
  providedIn: 'root',
})
export class ScanCigarService {
  constructor() {}

  scanPicture(image) {
    return new Observable((o: Observer<any>) => {
      const mImage = new MarvinImage();
      mImage.load(image, () => {
        const rect = Segmenter.processCigar(mImage);
        o.next({
          left: rect[0],
          top: rect[1],
          right: rect[2],
          bottom: rect[3],
          width: rect[2] - rect[0],
          height: rect[3] - rect[1],
          angle: 0,
        });
        return o.complete();
      });
    });
  }
}
