import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionSheetController,
  InfiniteScrollCustomEvent,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFooter,
} from '@ionic/angular/standalone';
import { ICigarLogListType, IProductSearch, IState } from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { CigarsService } from '@services/cigars.service';
import { ModalService } from '@services/modal.service';
import { CigarInfoComponent } from '@shared/components/cigar-info/cigar-info.component';
import { CigarSearchHeaderComponent } from '@shared/components/cigar-search/cigar-search-header.component';
import * as CigarsSearchActions from '@store/actions/cigars-search';
import * as CigarsSearchSelectors from '@store/selectors/cigars-search';
import { isListHumidor } from '@utils/cigarlogs';
import { AppRoutes } from '@utils/routes';
import { combineLatest } from 'rxjs';
import { addIcons } from 'ionicons';
import { close, search } from 'ionicons/icons';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    CigarSearchHeaderComponent,
    RxFor,
    RxLet,
    CigarInfoComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFooter,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title> {{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <cigar-search-header></cigar-search-header>
    </ion-header>

    <ion-content>
      <ng-container *rxLet="showSearchTip$; let showSearchTip">
        <div
          *ngIf="showSearchTip; else list"
          class="h-full flex justify-center items-center"
        >
          <div class="mx-auto flex items-center px-8">
            <ion-icon
              class="h-12 w-12 flex-shrink-0 text-gray-500 dark:text-gray-400"
              name="search"
            ></ion-icon>
            <div class="ml-4 flex flex-auto flex-col">
              <h3 class="font-medium m-0 text-gray-800 dark:text-gray-200">
                Search your favorite cigars
              </h3>
              <p class="text-sm text-gray-500 dark:text-gray-400 mt-1 mb-0">
                By Cigar/Brand name, shape, origin, length and more...
              </p>
            </div>
          </div>
        </div>
        <ng-template #list>
          <ion-list *ngIf="cigars$ | async as cigars" lines="full">
            <ion-item
              lines="none"
              class="no-results ion-text-center"
              *ngIf="!cigars.length && (isLoading$ | async) === false"
            >
              <ion-label>
                <h2>No Search Results</h2>
              </ion-label>
            </ion-item>
            <cigar-info
              *rxFor="let cigar of cigars; trackBy: 'GeneratedId'"
              [cigar]="cigar"
              [name]="cigar.Name"
              [rating]="cigar.RatingSummary"
              [prices]="cigar.Prices"
              [minBoxQty]="cigar.MinBoxQty"
              [maxBoxQty]="cigar.MaxBoxQty"
              [inLists]="cigar.InLists"
              (cigarClicked)="addCigar(cigar)"
            >
            </cigar-info>
            <ion-infinite-scroll
              [disabled]="(showMore$ | async) === false"
              threshold="100px"
              (ionInfinite)="doLoadMore($event)"
            >
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-list>
        </ng-template>
      </ng-container>
    </ion-content>

    <ion-footer *ngIf="showFooter$ | async">
      <ion-item>
        <ion-label class="ion-text-wrap"
          >Can't find the cigar your are looking for?</ion-label
        >
        <ion-button slot="end" (click)="goToCustom()"
          >Add your cigar</ion-button
        >
      </ion-item>
    </ion-footer>
  `,
  styles: [
    `
      cigar-info {
        border-color: var(
          --ion-item-border-color,
          var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
        );
        border-width: 0 0 1px 0;
        border-style: solid;
        padding: 5px 0;
      }
    `,
  ],
})
export class SearchModal implements OnInit {
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @Input() title: string;
  @Input() addTo?: ICigarLogListType;

  list$ = this.store.pipe(select(CigarsSearchSelectors.selectList));
  isLoading$ = this.store.pipe(select(CigarsSearchSelectors.selectIsLoading));
  cigars$ = this.store.pipe(select(CigarsSearchSelectors.selectCigarsSearch));
  showMore$ = this.store.pipe(select(CigarsSearchSelectors.selectShowMore));
  showSearchTip$ = combineLatest(
    [
      this.store.pipe(select(CigarsSearchSelectors.selectTerm)),
      this.store.pipe(select(CigarsSearchSelectors.selectHasFilters)),
    ],
    (term, hasFilters) => !term.length && !hasFilters
  );
  showFooter$ = combineLatest(
    [
      this.store.pipe(select(CigarsSearchSelectors.selectIsInit)),
      this.showSearchTip$,
    ],
    (init, showSearchTip) => init && !showSearchTip
  );

  constructor(
    private modalService: ModalService,
    private cigarsService: CigarsService,
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    protected store: Store<IState>
  ) {
    addIcons({ close, search });
  }

  ngOnInit() {
    this.list$.pipe(untilDestroyed(this)).subscribe((list) => {
      this.content.scrollToTop();
    });
  }

  dismiss() {
    this.modalService.dismiss(null, 'cancel');
  }

  doLoadMore(event?: InfiniteScrollCustomEvent) {
    this.store.dispatch(CigarsSearchActions.CigarsSearchRequestAction({}));
    const sub = this.isLoading$.subscribe((isLoading) => {
      if (!isLoading) {
        event.target.complete();
        sub.unsubscribe();
      }
    });
  }

  goToCustom() {
    this.router.navigate(
      ['custom-cigar', 'brand-search'],
      this.addTo ? { queryParams: { addTo: this.addTo } } : {}
    );
    this.dismiss();
  }

  async addCigar(product: IProductSearch) {
    if (this.addTo) {
      const isHumidor = isListHumidor(this.addTo);

      if (isHumidor) {
        if (product.ProductId) {
          this.modalService.dismiss(
            { ProductId: product.ProductId, LineId: null },
            'submit'
          );
        } else {
          const result = await this.cigarsService.displayLineSizeChoice(
            product.LineId
          );
          if (result) {
            this.modalService.dismiss(result, 'submit');
          }
        }
      } else {
        const isLine = product.LineId && !product.ProductId;
        if (isLine) {
          const result = await this.cigarsService.displayLineSizeChoice(
            product.LineId,
            true
          );
          if (result) {
            this.modalService.dismiss(result, 'submit');
          }
        } else {
          this.modalService.dismiss(
            { ProductId: product.ProductId, LineId: product.LineId },
            'submit'
          );
        }
      }
    } else {
      if (!product.InLists?.length) {
        this.modalService.dismiss(AppRoutes.cigar(product.GeneratedId), 'goTo');
      } else {
        const actionSheet = await this.actionSheetCtrl.create({
          header: 'Where do you want to go ?',
          buttons: [
            {
              text: 'Cigar Details',
              handler: () => {
                this.modalService.dismiss(
                  AppRoutes.cigar(product.GeneratedId),
                  'goTo'
                );
              },
            },
            ...product.InLists.map((list) => {
              const isHumidor = isListHumidor(list.ListId);
              return {
                text: isHumidor ? `Humidor: ${list.ListTitle}` : list.ListTitle,
                handler: () => {
                  this.modalService.dismiss(
                    AppRoutes.myCigarLog(list.ListId, list.LogId),
                    'goTo'
                  );
                },
              };
            }),
          ],
        });
        actionSheet.present();
      }
    }
  }
}
