import { Injectable, NgZone } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { PushNotifications } from '@capacitor/push-notifications';
import { environment } from '@environments/environment';
import { Platform, ToastController } from '@ionic/angular/standalone';
import { IState } from '@models';
import {
  IPushNotification,
  IPushNotificationData,
} from '@models/notifications';
import { Store } from '@ngrx/store';
import { AppRoutes } from '@utils/routes';
import debug from 'debug';
import { firstValueFrom } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { NotificationsService } from './notifications.service';

const log = debug('PushNotificationsService');
@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private platform: Platform,
    private notificationsService: NotificationsService,
    private router: Router,
    private afMessaging: AngularFireMessaging,
    protected zone: NgZone,
    private toastController: ToastController,
    private store: Store<IState>
  ) {}

  init() {
    log('init');
    this.addListeners();
    this.register();
  }

  askForPermission() {
    log('askForPermission');
    if (this.platform.is('capacitor')) {
      PushNotifications.requestPermissions();
    } else {
      this.afMessaging.requestPermission.subscribe();
    }
  }

  private redirectNotification(data: IPushNotificationData) {
    log('redirectNotification', data);
    if (data.RedirectUrl) {
      Browser.open({ url: data.RedirectUrl });
    } else if (data.Social) {
      try {
        const socialData = JSON.parse(data.Social);
        log('socialData', socialData);

        if (socialData.Action === 'Follow') {
          const userId = socialData.UserId;
          this.router.navigate([AppRoutes.socialUser('users', userId)]);
        } else if (socialData.SocialPostId) {
          const postId = socialData.SocialPostId;
          this.router.navigate([AppRoutes.socialPost(postId)]);
        } else {
          this.router.navigate([AppRoutes.notifications()]);
        }
      } catch (error) {
        this.router.navigate([AppRoutes.notifications()]);
      }
    } else if (data.ProductId) {
      const product = `P-${data.ProductId}`;
      this.router.navigate([AppRoutes.cigar(product)]);
    } else if (data.LineId) {
      const line = `L-${data.LineId}`;
      this.router.navigate([AppRoutes.cigar(line)]);
    } else if (data.HumidorId) {
      this.router.navigate([AppRoutes.myHumidor(data.HumidorId)]);
    } else {
      this.router.navigate([AppRoutes.notifications()]);
    }
  }

  private async handleForegroundNotification(data: IPushNotificationData) {
    log('handleForegroundNotification', data);
    // @Todo use UnreadNotifications to update the counter as in plantern
    // this.emitterService.increaseNotificationCounter();

    const toast = await this.toastController.create({
      header: 'New notification',
      position: environment.toastPosition,
      duration: environment.toastDuration,
      buttons: [
        {
          text: 'Open',
          handler: () => {
            this.router.navigate([AppRoutes.notifications()]);
          },
        },
      ],
    });
    toast.present();
  }

  private async subscribe(token: string) {
    log('subscribe', token);
    return firstValueFrom(this.notificationsService.subscribe(token));
  }

  private async addListeners() {
    if (this.platform.is('capacitor')) {
      await PushNotifications.addListener('registration', (token) => {
        log('registration event', token);
        this.subscribe(token.value);
      });

      await PushNotifications.addListener('registrationError', (err) => {
        log('Registration error: ', err.error);
      });

      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          this.handleForegroundNotification(
            notification.data as IPushNotificationData
          );
        }
      );

      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          log('Push notification action performed', notification);
          if (notification.actionId === 'tap') {
            this.zone.run(() => {
              this.redirectNotification(
                notification.notification.data as IPushNotificationData
              );
            });
          }
        }
      );
    } else {
      this.afMessaging.messages
        .pipe(
          tap((message) => {
            const notification = message as unknown as IPushNotification;
            this.handleForegroundNotification(notification.data);
          })
        )
        .subscribe();

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data.action === 'NOTIFICATION_CLICK') {
            this.handleForegroundNotification(event.data.data);
          }
        });
      }
    }
  }

  public async unregister() {
    log('unregister');
    if (this.platform.is('capacitor')) {
      return PushNotifications.unregister();
    } else {
      return firstValueFrom(
        this.afMessaging.getToken.pipe(
          mergeMap((token) => {
            return this.afMessaging.deleteToken(token || '');
          })
        )
      );
    }
  }

  public async register() {
    log('register');
    if (this.platform.is('capacitor')) {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
      // only android

      if (this.platform.is('android')) {
        await PushNotifications.createChannel({
          id: 'cigarScanner', // required
          name: 'Cigar Scanner Notification Channel', // required
          importance: 3, // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
          visibility: 0, // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
          sound: 'alert_sound', // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
          lights: true, // enable lights for notifications
          vibration: true, // enable vibration for notifications
        });
      }
    } else {
      //  easier way of requesting permission and getting tokens
      this.afMessaging.requestToken
        .pipe(
          tap((token) => {
            if (token) {
              this.subscribe(token);
            }
          })
        )
        .subscribe();
    }
  }
}
