import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IDataSummary } from '@models/datasummary';
import { of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataSummaryService {
  constructor(private http: HttpClient) {}

  summary: IDataSummary;
  dataSummary() {
    if (this.summary) {
      return of(this.summary);
    }
    return this.http.get<IDataSummary>('datasummary').pipe(
      tap((searchParams) => {
        this.summary = searchParams;
      })
    );
  }
}
