import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, getInitialFilters } from './../reducers/my-cigars';
import {
  IState,
  IMyCigarsState,
  ICigarLog,
  ICigarLogStored,
  ICigar,
  ICigarLite,
  ICigarLogListType,
  ICigarLogFilters,
} from '@models';
import { selectCigarEntities } from './cigars.selectors';

export const selectMyCigarsState = createFeatureSelector<
  IState,
  IMyCigarsState
>('myCigars');

function fromCigarLogStoredToCigarLog(
  cigarLogStored: ICigarLogStored,
  cigarEntities: Record<string, ICigar | ICigarLite>
): ICigarLog {
  const cigarCreatedId = cigarLogStored.CigarDetails;
  return {
    ...cigarLogStored,
    CigarDetails: cigarEntities[cigarCreatedId],
  };
}

const { selectEntities } = adapter.getSelectors(
  (state: IState) => state.myCigars
);

export const selectMyCigars = (state: IState) => state.myCigars;
export const selectJournalTotal = (state: IState) =>
  state.myCigars['journal']?.total || 0;
export const selectFavoritesTotal = (state: IState) =>
  state.myCigars['favorites']?.total || 0;
export const selectWishlistTotal = (state: IState) =>
  state.myCigars['wishlist']?.total || 0;
export const selectMyCigarsListTotal = (
  state: IState,
  type: ICigarLogListType
) => state.myCigars[type].total;

export const selectMyCigarsLoading = createSelector(
  (state: IState, type: ICigarLogListType) => state.myCigars[type]?.isLoading,
  (isLoading) => isLoading
);

const defaultFilters = getInitialFilters();
export const selectFilters = createSelector(
  selectMyCigars,
  (_state, type) => type,
  (state: IMyCigarsState, type: string) =>
    state[type]?.filters || defaultFilters
);

export const showFiltersToolbar = createSelector(
  selectFilters,
  (filters?: ICigarLogFilters) =>
    filters
      ? Object.keys(filters).some((key) => filters[key] !== defaultFilters[key])
      : false
);

export const hasSearchTextFilter = createSelector(
  selectFilters,
  (filters: ICigarLogFilters) => !!filters.search
);

export const selectMyCigarsShowMore = (
  state: IState,
  type: ICigarLogListType
) => state.myCigars[type]?.showMore;

export const selectMyCigarsListIds = (state: IState, type: ICigarLogListType) =>
  state.myCigars[type]?.ids;

export const selectMyCigarsListLength = createSelector(
  selectMyCigarsListIds,
  (ids) => ids?.length || 0
);

export const selectMyCigarsList = createSelector(
  selectEntities,
  selectMyCigarsListIds,
  selectCigarEntities,
  (entities, ids: number[], cigarEntities) =>
    ids?.map((logId) =>
      fromCigarLogStoredToCigarLog(entities[logId], cigarEntities)
    )
);

export const selectOneLog = createSelector(
  selectEntities,
  (_state, logId) => logId,
  selectCigarEntities,
  (entities, logId: number, cigarEntities) =>
    entities[logId] &&
    fromCigarLogStoredToCigarLog(entities[logId], cigarEntities)
);

export const selectLoadingItems = createSelector(
  selectMyCigarsState,
  (state) => state.loadingItems
);

export const isCigarlogLoading = createSelector(
  selectLoadingItems,
  (_state, id: number) => id,
  (entities, cigarlogId: number) => entities[cigarlogId]
);
