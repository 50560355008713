import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular/standalone';
import HumidorHistoryModal from '@modals/humidor-history-modal.component';
import {
  ScannerModal,
  ScannerModalResult,
} from '@modals/scanner-modal/scanner-modal';
import {
  ScannerNoResultsModal,
  ScannerNoResultsModalResult,
} from '@modals/scanner-modal/scanner-no-results-modal';
import {
  ScannerAIResultsModal,
  ScannerAIResultsModalResult,
} from '@modals/scanner-modal/scanner-results-modal';
import { SearchModal } from '@modals/search-modal.component';
import { ICigar, ICigarLogListType, IScan, IState } from '@models';
import { select, Store } from '@ngrx/store';
import { ToastService } from '@services/toast.service';
import { JOURNAL_LIST } from '@shared/models/cigar-log.model';
import { CameraService } from '@shared/services/camera.service';
import * as myCigarsActions from '@store/actions/my-cigars';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { getCigarCreatedId } from '@utils/cigar';
import { isListHumidor } from '@utils/cigarlogs';
import { getErrorMessage } from '@utils/errors';
import { AppRoutes } from '@utils/routes';
import { first, firstValueFrom } from 'rxjs';

import { ModalService } from './modal.service';

@Injectable({ providedIn: 'root' })
export class ModalsService {
  settings$ = this.store.pipe(select(userSelectors.selectSettings));
  constructor(
    private modalService: ModalService,
    private store: Store<IState>,
    private cameraService: CameraService,
    private toastService: ToastService,
    private router: Router,
    public platform: Platform
  ) {}

  async getHumidorHistoryModal(humidorId: string, productId?: number) {
    return this.modalService.createCSModal(HumidorHistoryModal, {
      productId,
      humidorId,
    });
  }

  async addFromCamera(addTo?: ICigarLogListType) {
    this.store.dispatch(userActions.SettingsRequest());

    try {
      const { image, product } = await this.cameraService.showCameraOptions(
        true
      );

      console.log({ image });

      if (product) {
        const settings = await firstValueFrom(this.settings$.pipe(first()));
        if (addTo || settings?.AutoAddScanned) {
          this.store.dispatch(
            myCigarsActions.MyCigarsCreateRequestAction({
              ProductId: product.ProductId,
              LineId: product.LineId,
              listType: addTo || JOURNAL_LIST,
            })
          );
        } else {
          this.router.navigate([AppRoutes.cigar(getCigarCreatedId(product))]);
        }
        return;
      }

      const modal = await this.modalService.createCSModal(
        ScannerModal,
        {
          addTo,
          image,
        },
        {
          mobile: {
            handle: false,
            breakpoints: undefined,
          },
        }
      );
      await modal.present();
      const { data, role } =
        (await modal.onWillDismiss()) as ScannerModalResult;

      if (role === 'not-found') {
        this.openNotFound(data.scanLog, addTo);
      } else if (role === 'mutliple-results') {
        this.openAIResultsModal(data.scanLog, data.location, addTo);
      } else if (role === 'add-cigar') {
        this.addCigarToList(
          data.scanLog.originalImageUrl,
          data.scanLog.logId,
          data.cigar,
          data.addTo,
          data.location
        );
      } else if (role === 'add-and-go-to-cigar') {
        this.addCigarToList(
          data.scanLog.originalImageUrl,
          data.scanLog.logId,
          data.cigar,
          data.addTo,
          data.location
        );
        this.navigateToCigar(data.cigar, data.scanLog.logId);
      } else if (role === 'go-to-cigar') {
        this.navigateToCigar(data.cigar, data.scanLog.logId);
      } else {
        // this.toastService.show('No image found');
      }
    } catch (error) {
      console.log({ error });
      // error can be false here if cancelling the choice
      if (error) {
        this.toastService.show(getErrorMessage(error));
      }
    }
  }

  async addFromSearch(addTo?: ICigarLogListType) {
    try {
      const modal = await this.modalService.createCSModal(SearchModal, {
        addTo,
        title: addTo
          ? isListHumidor(addTo)
            ? 'Add to Humidor'
            : `Add to ${addTo}`
          : 'Search',
      });
      await modal.present();

      const { data, role } = (await modal.onWillDismiss()) as
        | {
            data: {
              LineId: number;
              ProductId: number;
            };
            role: 'submit';
          }
        | {
            data: string;
            role: 'goTo';
          };
      if (role === 'submit') {
        this.store.dispatch(
          myCigarsActions.MyCigarsCreateRequestAction({
            listType: addTo,
            ...data,
          })
        );
      } else if (role === 'goTo') {
        this.router.navigateByUrl(data);
      }
    } catch (error) {
      console.log({ error });
      // error can be false here if cancelling the choice
      if (error) {
        this.toastService.showError();
      }
    }
  }

  async openNotFound(scanLog: IScan, addTo?: ICigarLogListType) {
    const modal = await this.modalService.createCSModal(ScannerNoResultsModal, {
      scanLog,
    });
    await modal.present();

    const { data, role } =
      (await modal.onWillDismiss()) as ScannerNoResultsModalResult;

    if (role === 'camera') {
      this.addFromCamera(addTo);
    } else if (role === 'search') {
      this.addFromSearch(addTo);
    }
  }

  async openAIResultsModal(
    scanLog: IScan,
    location?: string,
    addTo?: ICigarLogListType
  ) {
    const modal = await this.modalService.createCSModal(ScannerAIResultsModal, {
      scanLog,
      addTo,
    });
    await modal.present();

    const { data, role } =
      (await modal.onWillDismiss()) as ScannerAIResultsModalResult;

    if (role === 'add-cigar') {
      this.addCigarToList(
        data.scanLog.originalImageUrl,
        null, // since the user selected the cigar manually, we don't need to pass the recognition id
        data.cigar,
        data.addTo,
        location
      );
    } else if (role === 'add-and-go-to-cigar') {
      this.addCigarToList(
        data.scanLog.originalImageUrl,
        null, // since the user selected the cigar manually, we don't need to pass the recognition id
        data.cigar,
        data.addTo,
        location
      );
      this.navigateToCigar(data.cigar);
    } else if (data?.cigar) {
      this.navigateToCigar(data.cigar);
    }
  }

  private addCigarToList(
    UserImageUrl: string,
    RecognitionId: number | null,
    cigar: ICigar,
    addTo?: ICigarLogListType,
    Location?: string
  ) {
    this.store.dispatch(
      myCigarsActions.MyCigarsCreateRequestAction({
        ProductId: cigar.ProductId,
        LineId: cigar.LineId,
        listType: addTo || JOURNAL_LIST,
        UserImageUrl,
        RecognitionId,
        Location,
      })
    );
  }

  private navigateToCigar(cigar: ICigar, recordId: number | null = null) {
    this.router.navigate([AppRoutes.cigar(getCigarCreatedId(cigar))], {
      queryParams: {
        recordId,
      },
    });
  }
}
