import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter } from './../reducers/cigars';
import {
  IState,
  ICigarsState,
  ICigar,
  ICigarLite,
  IProductSearch,
} from '@models';
import { getCigarCreatedId } from '@utils/cigar';

export const selectCigarsState = createFeatureSelector<IState, ICigarsState>(
  'cigars'
);
const { selectEntities } = adapter.getSelectors(selectCigarsState);

export const selectCigarEntities = selectEntities;

export const selectOneCigar = createSelector(
  selectEntities,
  (_state, cigarId) => cigarId,
  (entities, cigarId: number) => entities[cigarId]
);

export const selectLoadingItems = createSelector(
  selectCigarsState,
  (state) => state.loadingItems
);
export const isCigarLoading = createSelector(
  selectLoadingItems,
  (_state, cigar: ICigar | ICigarLite | IProductSearch) =>
    getCigarCreatedId(cigar),
  (entities, cigarId: string) => entities[cigarId]
);
