import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { IProductSearchFilters, IState, SearchTypes } from '@models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { CigarsSearchService } from '@services/cigars-search.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import * as CigarsSearchActions from '@store/actions/cigars-search';
import * as CigarsSearchSelectors from '@store/selectors/cigars-search';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { IonButton, IonButtons, IonIcon, IonLabel, IonProgressBar, IonSearchbar, IonSegment, IonSegmentButton, IonToolbar } from "@ionic/angular/standalone";
import { ModalService } from '@services/modal.service';
import { addIcons } from "ionicons";
import { options } from "ionicons/icons";
import { firstValueFrom } from 'rxjs';
import {
  AdvancedSearchModal,
  IAdvancedSearchModalData,
} from './advanced-search.modal';

@UntilDestroy()
@Component({
    selector: 'cigar-search-header',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AdvancedSearchModal,
        IonToolbar,
        IonSearchbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonSegment,
        IonSegmentButton,
        IonLabel,
        IonProgressBar
    ],
    template: `
    <ion-toolbar>
      <form [formGroup]="formGroup">
        <ion-searchbar
          mode="ios"
          class="ion-padding-top"
          debounce="500"
          placeholder="Search by Cigar or Brand"
          showClearButton="focus"
          enterkeyhint="search"
          inputmode="search"
          [value]="term$ | async"
          (ionChange)="updateTerm($event)"
          (ionClear)="clearTerm()"
        ></ion-searchbar>
      </form>
      <ion-buttons slot="end">
        <ion-button
          [fill]="(hasFilters$ | async) ? 'solid' : 'outline'"
          color="primary"
          size="large"
          shape="round"
          (click)="openAdvancedSearch()"
          [disabled]="isFiltersModalOpened"
        >
          <ion-icon name="options"></ion-icon> </ion-button
      ></ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
      <ion-segment [value]="list$ | async" (ionChange)="changeList($event)">
        <ion-segment-button value="all">
          <ion-label>All Cigars</ion-label>
        </ion-segment-button>
        <ion-segment-button value="lists">
          <ion-label>Lists</ion-label>
        </ion-segment-button>
        <ion-segment-button value="humidors">
          <ion-label>Humidors</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
    <ion-progress-bar
      type="indeterminate"
      *ngIf="isLoading$ | async"
    ></ion-progress-bar>
  `,
    styles: [
        `
      :host {
        display: block;
      }
      ion-searchbar {
        font-size: var(--font-size-big);
        --box-shadow: none;
        padding-bottom: 0;
        flex: 1;
        ::ng-deep input {
          padding-inline-end: 0;
        }
      }
      ion-toolbar {
        ::ng-deep ion-buttons {
          min-height: 52px;
        }
      }

      ion-toolbar.filters {
        /* --background: transparent; */
      }
    `,
    ],
})
export class CigarSearchHeaderComponent implements OnInit {
    isLoading$ = this.store.pipe(select(CigarsSearchSelectors.selectIsLoading));
    term$ = this.store.pipe(select(CigarsSearchSelectors.selectTerm));
    list$ = this.store.pipe(select(CigarsSearchSelectors.selectList));
    filters$ = this.store.pipe(select(CigarsSearchSelectors.selectFilters));
    hasFilters$ = this.store.pipe(select(CigarsSearchSelectors.selectHasFilters));

    filters: IProductSearchFilters;
    formGroup = this.fb.group({
        term: ['', [Validators.required, Validators.minLength(2)]],
    });
    isFiltersModalOpened = false;

    constructor(
        private fb: UntypedFormBuilder,
        protected store: Store<IState>,
        protected modalService: ModalService,
        private cigarsSearchService: CigarsSearchService,
        private analyticsService: AnalyticsService
    ) {
        addIcons({ options });
    }

    ngOnInit() {
        this.filters$
            .pipe(
                untilDestroyed(this),
                map((filters) => (this.filters = filters))
            )
            .subscribe();

        this.formGroup
            .get('term')
            .valueChanges.pipe(
                untilDestroyed(this),
                map((term) => term.trim()),
                distinctUntilChanged(),
                // filter((term) => term.trim().length >= 2),
                map((term) => {
                    this.analyticsService.search(term);
                    this.store.dispatch(
                        CigarsSearchActions.CigarsSearchUpdateTermAction({
                            term,
                        })
                    );
                })
            )
            .subscribe();
    }

    async openAdvancedSearch() {
        this.isFiltersModalOpened = true;
        try {
            const searchParams = await firstValueFrom(
                this.cigarsSearchService.getSearchParams()
            );

            const modal = await this.modalService.createCSModal(AdvancedSearchModal, {
                searchParams,
                currentValues: this.filters,
            } as IAdvancedSearchModalData);
            await modal.present();
            const { data, role } = (await modal.onWillDismiss()) as {
                data: IProductSearchFilters;
                role: 'backdrop' | 'cancel' | 'clear' | 'submit';
            };
            if (role === 'clear') {
                this.clearFilters();
            } else if (role === 'submit' && data) {
                this.store.dispatch(
                    CigarsSearchActions.CigarsSearchUpdateFiltersAction({
                        filters: data,
                    })
                );
            }
        } catch (error) { }
        this.isFiltersModalOpened = false;
    }

    clearFilters() {
        this.store.dispatch(
            CigarsSearchActions.CigarsSearchUpdateFiltersAction({
                filters: null,
            })
        );
    }

    changeList(
        e: CustomEvent<{
            value: SearchTypes;
        }>
    ) {
        this.store.dispatch(
            CigarsSearchActions.CigarsSearchUpdateListAction({
                list: e.detail.value,
            })
        );
    }

    updateTerm(e: Event) {
        const value = (e as CustomEvent<{ value: string }>).detail.value;
        this.formGroup.get('term').setValue(value);
    }

    clearTerm() {
        this.formGroup.get('term').setValue('');
    }
}
